import React, { useState, useEffect } from 'react';
import axios from 'axios';
import draftValueMatrix from './data/draftValueMatrix.json';
import tradeValueMatrix from './data/tradeValueMatrix.json';
import draftCapital from './data/draftCapital.json';

function TradeMachine() {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([null, null]);
  const [teamPlayers, setTeamPlayers] = useState({});
  const [tradeProposal, setTradeProposal] = useState({ team1: [], team2: [] });
  const [requiredTradeValue, setRequiredTradeValue] = useState(0);
  const [remainingTradeValue, setRemainingTradeValue] = useState(0);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BASEURL+'/api/teams');
        const sortedTeams = response.data
          .filter(team => team.teamName)
          .sort((a, b) => a.teamName.localeCompare(b.teamName));
        setTeams(sortedTeams);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    const fetchPlayers = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BASEURL+'/api/players');
        setPlayers(response.data);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };

    fetchTeams();
    fetchPlayers();
  }, []);

  const handleTeamSelect = (index, teamName) => {
    const newSelectedTeams = [...selectedTeams];
    newSelectedTeams[index] = teamName;
    setSelectedTeams(newSelectedTeams);

    const playersFromTeam = players.filter(player => player.teamName === teamName);
    setTeamPlayers({ ...teamPlayers, [teamName]: playersFromTeam });
  };

  const handlePlayerSelection = (teamIndex, playerName) => {
  const teamKey = teamIndex === 0 ? 'team1' : 'team2';
  const player = teamPlayers[selectedTeams[teamIndex]].find(player => player.playerName === playerName);

    togglePlayerInTrade(teamIndex, player);
  };


  const handleDraftSelection = (teamIndex, pick) => {
    const teamKey = teamIndex === 0 ? 'team1' : 'team2';
    const pickValue = draftValueMatrix[pick] || 0;  // Fetch points based on the pick number
    
  const draftPickObj = {
    pick: pick,  // The actual pick number like "35"
    year: '2024',
    currency: pickValue  // The points for the actual pick
  };

  toggleDraftInTrade(teamIndex, draftPickObj);
  };



  const togglePlayerInTrade = (teamIndex, player) => {
  const teamKey = teamIndex === 0 ? 'team1' : 'team2';
  const otherTeamKey = teamIndex === 0 ? 'team2' : 'team1';

  setTradeProposal((prevState) => {
    const isPlayerInTrade = prevState[teamKey].some(item => item.playerName === player.playerName);
    const updatedTeamProposal = isPlayerInTrade
      ? prevState[teamKey].filter(item => item.playerName !== player.playerName)
      : [...prevState[teamKey], player];

    const newTradeValue = calculateTradeValue(updatedTeamProposal);
    const otherTeamTradeValue = calculateTradeValue(prevState[otherTeamKey]);

    if (teamIndex === 0) {
      setRequiredTradeValue(newTradeValue);
      setRemainingTradeValue(otherTeamTradeValue - newTradeValue);
    } else {
      setRemainingTradeValue(requiredTradeValue - newTradeValue);
    }

    return { ...prevState, [teamKey]: updatedTeamProposal };
  });
};


  const toggleDraftInTrade = (teamIndex, draftPickObj) => {
  const teamKey = teamIndex === 0 ? 'team1' : 'team2';
  const otherTeamKey = teamIndex === 0 ? 'team2' : 'team1';

  setTradeProposal((prevState) => {
    const isDraftInTrade = prevState[teamKey].some(
      (item) => item.pick === draftPickObj.pick && item.year === draftPickObj.year
    );

    const updatedTeamProposal = isDraftInTrade
      ? prevState[teamKey].filter((item) => !(item.pick === draftPickObj.pick && item.year === draftPickObj.year))
      : [...prevState[teamKey], draftPickObj];

    const newTradeValue = calculateTradeValue(updatedTeamProposal);
    const otherTeamTradeValue = calculateTradeValue(prevState[otherTeamKey]);

    if (teamIndex === 0) {
      setRequiredTradeValue(newTradeValue);
      setRemainingTradeValue(otherTeamTradeValue - newTradeValue);
    } else {
      setRemainingTradeValue(requiredTradeValue - newTradeValue);
    }

    return { ...prevState, [teamKey]: updatedTeamProposal };
  });
  };

  const removeItemFromTrade = (teamIndex, itemToRemove) => {
    const teamKey = teamIndex === 0 ? 'team1' : 'team2';
    const otherTeamKey = teamIndex === 0 ? 'team2' : 'team1';

    setTradeProposal((prevState) => {
      const updatedTeamProposal = prevState[teamKey].filter(item => item !== itemToRemove);

      const newTradeValue = calculateTradeValue(updatedTeamProposal);
      const otherTeamTradeValue = calculateTradeValue(prevState[otherTeamKey]);

      if (teamIndex === 0) {
        setRequiredTradeValue(newTradeValue);
        setRemainingTradeValue(otherTeamTradeValue - newTradeValue);
      } else {
        setRemainingTradeValue(requiredTradeValue - newTradeValue);
      }

      return { ...prevState, [teamKey]: updatedTeamProposal };
    });
  };

  const validateTrade = () => {
    const team1Value = calculateTradeValue(tradeProposal.team1);
    const team2Value = calculateTradeValue(tradeProposal.team2);

    return team1Value >= requiredTradeValue && team2Value >= requiredTradeValue;
  };

  const calculateTradeValue = (items) => {
    return items.reduce((totalValue, item) => {
      if (item.tradeValueTier) {
        // Fetch the player's points based on the tradeValueTier from tradeValueMatrix
        const tierValue = tradeValueMatrix[item.tradeValueTier]?.currency || 0;
        return totalValue + tierValue;
      } else if (item.currency) {
        // For draft picks, use direct currency
        return totalValue + item.currency;
      }
      return totalValue;
    }, 0);
  };


  const startOver = () => {
    setTradeProposal({ team1: [], team2: [] });
    setRequiredTradeValue(0);
    setRemainingTradeValue(0);
  };

  const bothTeamsHaveAssets = tradeProposal.team1.length > 0 && tradeProposal.team2.length > 0;

  return (
    <div className="trade-machine">
  <div className="trade-machine-header">
    <h1>AFL Trade Machine</h1>
    <div className="instructions">
      <p>Be the list boss. Build your trade packages by simply selecting two different teams, then add players and/or draft picks. Our smart list boss will then advise trade approved if you meet the required trade value.</p>
    </div>
      </div>
      <div className="trade-summary" style={{ display: tradeProposal.team1.length > 0 || tradeProposal.team2.length > 0 ? 'flex' : 'none' }}>
        {/* Team 1 Summary */}
        <div className="team-summary">
          <h4>
            <img 
              src={`/logos/${selectedTeams[0]}.png`} 
              alt={`${selectedTeams[0]} logo`} 
              className="team-logo" 
            />
            {selectedTeams[0]}
          </h4>
          <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
            {tradeProposal.team1.map((item, i) => (
              <li key={i}>
                {item.pick 
                  ? `Pick (${item.pick})` 
                  : item.playerName} - 
                {item.currency ? ` ${item.currency} points` : 
                  (item.tradeValueTier ? `${tradeValueMatrix[item.tradeValueTier]?.currency || 'No value available'} points` : 
                  'No value available')}
                <button onClick={() => removeItemFromTrade(0, item)} style={{ marginLeft: '10px', color: 'red', cursor: 'pointer' }}>
                  X
                </button>
              </li>
            ))}
          </ul>
        </div>
        {/* Team 2 Summary */}
        <p className="trade-for">For</p>
        {selectedTeams[1] && (
          <div className="team-summary">
            <h4>
              <img 
                src={`/logos/${selectedTeams[1]}.png`} 
                alt={`${selectedTeams[1]} logo`} 
                className="team-logo" 
              />
              {selectedTeams[1]}
            </h4>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              {tradeProposal.team2.map((item, i) => (
                <li key={i}>
                  {item.pick 
                    ? `Pick (${item.pick})` 
                    : item.playerName} - 
                  {item.currency ? ` ${item.currency} points` : 
                    (item.tradeValueTier ? `${tradeValueMatrix[item.tradeValueTier]?.currency || 'No value available'} points` : 
                    'No value available')}
                  <button onClick={() => removeItemFromTrade(1, item)}>
                    X
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="trade-result">
        {bothTeamsHaveAssets && remainingTradeValue <= 0 && (
          <div className="trade-approved">Trade Approved</div>
        )}
        {bothTeamsHaveAssets && (
          <div className="trade-restart">
            <button onClick={startOver}>Reset</button>
          </div>
        )}
      </div>
      <div className="required-trade-match" style={{ display: requiredTradeValue > 0 ? 'block' : 'none' }}>
        <h4>Remaining Trade Value to Match: {remainingTradeValue} points</h4>
      </div>

      <div className="team-selection">
        {selectedTeams.map((team, index) => (
          <div key={index} className="team-details">
            <select
              onChange={(e) => handleTeamSelect(index, e.target.value)}
              value={team || ""}
              disabled={index === 1 && selectedTeams[0] === null}
            >
              <option value="">Select a team</option>
              {teams
                .filter(teamObj => teamObj.teamName !== "Delisted" && teamObj.teamName !== "Retired")
                .map(teamObj => (
                  <option key={teamObj.teamName} value={teamObj.teamName}>
                    {teamObj.teamName}
                  </option>
                ))}
            </select>
            {team && (
              <div className="team-details">
                <h4>Select players</h4>
                <select
                  onChange={(e) => handlePlayerSelection(index, e.target.value)}
                  value=""
                >
                  <option value="">Select players</option>
                  {teamPlayers[team]?.sort((a, b) => a.playerName.localeCompare(b.playerName)).map(player => (
                    <option key={player.playerName} value={player.playerName}>
                      {player.playerName} ({player.position})
                    </option>
                  ))}
                </select>
                <h4>Select draft picks</h4>
               <select
                  onChange={(e) => handleDraftSelection(index, e.target.value)}
                  value=""
                >
                  <option value="">Select draft picks</option>
                    {Object.entries(draftCapital[team] || {})
                      .flatMap(([key, picks]) => 
                        picks.map((pick, idx) => (
                          <option key={`pick-${idx}`} value={pick}>
                            {`Pick`} ({pick}) - {draftValueMatrix[pick] || 0} points
                          </option>
                        ))
                      )
                    }
                </select>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TradeMachine;
