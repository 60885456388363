import React, { useEffect, useState } from 'react';
import axios from 'axios';

function DraftOrder() {
  const [draftOrder, setDraftOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASEURL+'/api/draft-order')
      .then(response => {
        setDraftOrder(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the draft order!", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="page-section-top">
      <h1>AFL Draft Order 2024</h1>
      <p>Your clubs picks and points for the upcoming draft</p>
      <table>
        <thead>
          <tr>
            <th>Draft Pick</th>
            <th>Team Name</th>
            <th>Draft Points</th>
          </tr>
        </thead>
        <tbody>
          {draftOrder.map((order, index) => (
            <tr key={index}>
              <td>{order.draftPosition}</td>
              <td>
                <img src={`/logos/${order.teamName}.png`} alt={`${order.teamName} logo`} style={{ width: '20px', marginRight: '10px' }} />
                {order.teamName}
              </td>
              <td>{order.draftPoints}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DraftOrder;
