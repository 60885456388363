import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import DraftOrder from './components/DraftOrder';
import MockDraft from './components/MockDraft';
import ProspectRankings from './components/ProspectRankings';
import TradeMachine from './components/TradeMachine'; // Import the TradeMachine component
import Footer from './components/Footer.js';
import About from './components/About.js';

function App() {
  return (
    <Router>
      <div>
        <div className="beta-version-banner">
        <p>Try the <b>TANK Mock Draft & Trade Machine</b></p>
        </div>
        <header>
          <div>
            <div className="logo"><Link to="/">the<b>TANK</b></Link></div>
          </div>
          <nav>
            <ul>
              <li><Link to="/trade-machine">Trade Machine</Link></li>
              <li><Link to="/mock">Mock Draft</Link></li>
              <li><Link to="/draft-order">Draft Order</Link></li>
              <li><Link to="/board">Big Board</Link></li>
            </ul>
          </nav>
        </header>
        <Switch>
          <Route path="/trade-machine" component={TradeMachine} /> 
          <Route path="/mock" component={MockDraft} />
          <Route path="/" exact component={TradeMachine} />
          <Route path="/draft-order" component={DraftOrder} />
          <Route path="/board" component={ProspectRankings} />
          <Route path="/about-us" component={About} /> 
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
