import React, { useEffect, useState } from 'react';
import axios from 'axios';
import teams from './data/teams'; // Assuming teams.js is in the same directory

function ProspectRankings() {
  const [prospects, setProspects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASEURL+'/api/prospects')
      .then(response => {
        setProspects(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the prospects!", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="page-section-top">
      <h1>2024 AFL Draft Big Board</h1>
      <p>Our big board is based on a consensus of draft experts and collecting data to rank prospects</p>
      <table>
        <thead>
          <tr>
            <th>Power Rank</th>
            <th>Player Name</th>
            <th>Current Team</th>
            <th>Position</th>
            <th>Height</th>
          </tr>
        </thead>
        <tbody>
          {prospects.map((prospect, index) => {
            const team = teams.find(team => team.name === prospect.teamName);
            return (
              <tr key={index}>
                <td>{prospect.draftPosition}</td>
                <td>{prospect.playerName}</td>
                <td>
                  {team && (
                    <>
                      <img 
                        src={team.logo} 
                        alt={`${prospect.teamName} logo`} 
                        style={{ width: '20px', marginRight: '10px', verticalAlign: 'middle' }} 
                      />
                      {prospect.teamName}
                    </>
                  )}
                </td>
                <td>{prospect.position}</td>
                <td>{prospect.height}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ProspectRankings;
